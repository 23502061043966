  document.getElementById("menuButton").addEventListener("click", function() {
    this.classList.toggle("active"); //クリックしたものをactiveというクラスに切り替える
    document.getElementById("nav").classList.toggle("active");
    document.getElementById("mask").classList.toggle("active");
    document.getElementById("mask").addEventListener('click', function() {
     menuButton.classList.remove("active");
     nav.classList.remove("active");
     mask.classList.remove("active");
   });
  });

  document.getElementById("hiddenburger").addEventListener("click", function(){
    menuButton.classList.remove("active");
    nav.classList.remove("active");
    mask.classList.remove("active");
    faSearch.classList.remove("custom");
    searchNav.classList.remove("custom");
    mask.classList.remove("custom");
    logo.classList.remove("custom");
  })
